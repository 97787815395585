import React from "react";

import { Wrapper } from "./Footer.styles";

const FooterSection = () => {
    return(
        <Wrapper>&copy;2024 Movieratingo | All rights reserved. database credit: TMDB</Wrapper>
    )
}



export default FooterSection